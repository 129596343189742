import React from 'react';
import style from "./index.module.css";
import { coins, video } from '../../../assests';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';



const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


export const VideoSection = () => {

    const [open, setOpen] = React.useState(false);
    const [openSecond, setOpenSecond] = React.useState(false);
    
    const handleOpenSecond=()=>{
        setOpenSecond(true)
    }
    const handleCloseSecond=()=>{
        setOpenSecond(false)
    }
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
    <div className={`${style.banner_sectioN_video}`}>
        <div className={`${style.nested_banner}`}>
        <div className={`${style.top_section}`}>
            <div>
          <img src={coins} alt="coins" /> 
          </div>
          <div>
          <video src={video} width="100%" height="100%"  autoPlay={true} loop muted/>
          </div>
        </div>  
        <div className={`${style.heading}`}>
            <h1>NIL PLATFROM FOR ALL ATHELETS</h1>
        </div>
        <div className={`${style.btn_section}`}>
           <button onClick={handleOpen}>ENTER STUDENTS</button>
           <button onClick={handleOpenSecond}>ENTER FANS</button>
        </div>
        </div>
    </div>
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
  >
    <Box sx={{ ...styles, width: "40%" }}>
      <h2 id="child-modal-title" className={style.modal_setting}>Enter Student Details</h2>
      <p>File</p>
      <input type="file" />
      <br />
      <Button onClick={handleClose}>Close Child Modal</Button>
    </Box>
  </Modal>

  <Modal
    open={openSecond}
    onClose={handleCloseSecond}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
  >
    <Box sx={{ ...styles, width: "40%" }}>
      <h2 id="child-modal-title" className={style.modal_setting}>Enter Fans Details</h2>
      <p>File</p>
      <input type="file" />
      <br />
      <Button onClick={handleCloseSecond}>Close Child Modal</Button>
    </Box>
  </Modal>
  </>
  )
}
